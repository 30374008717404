<template>
  <div>
    <FormProperty @submit-requested="submitRequested"></FormProperty>
  </div>
</template>
 
<script>
import { mapActions } from 'vuex'
import FormProperty from '../forms/FormProperty.vue'

  export default {
  components: { FormProperty },
  methods: {
    ...mapActions('properties', ['addProperty']),
    submitRequested (propertieInfo) {
      this.addProperty(propertieInfo);
    },
  }

  }
</script> 
 
<style scoped>
thead > tr > th
{
  text-align: unset !important;
}
</style> 